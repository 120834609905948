@import '@/styles/_shared.scss';

.browse {
    padding-bottom: 24px;

    @include breakpoint-up('md') {
        padding-bottom: 72px;
    }

    .header {
        padding-top: 14px;
        padding-bottom: 14px;

        @include breakpoint-up('md') {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            padding-top: 72px;
            padding-bottom: 24px;
        }

        h1 {
            margin-bottom: 13px;

            @include breakpoint-up('md') {
                margin-bottom: 0;
            }
        }

        // header-link
        &-link {
            align-items: center;
            display: flex;
            @include breakpoint-up('md') {
                display: none;
            }
            a {
                margin-right: 7.77px;
                font-weight: 500;
                text-decoration: none;
            }
        }

        // header-search
        &-search {
            background-color: #fff;
            border-radius: 40px;
            border: 1px solid $fawn50-2;
            padding: 15.67px 0 15.65px 13.55px;

            form {
                @include breakpoint-up('md') {
                    display: flex;
                }    
                display: none;
                align-items: center;
            }

            button {
                border: none;
                background: #fff;
                padding: 0;
                border-radius: 40px;
            }

            svg {
                width: 25.95px;
                height: 23.69px;
            }

            input {
                border: none;
                border-radius: 40px;
                margin-left: 14.5px;
                color: $sitkablue;
                font-weight: 500;

                &::placeholder {
                    font-weight: 500;
                    color: $sitkablue;
                    font-weight: 500;
                }
            }
        }
    }
}