@import '@/styles/_shared.scss';

.slider__slide {
  position: relative;

  .image-wrap {
    position: relative;
    height: 40vh;

    @include breakpoint-down('xs') {
      height: 30vh;
    }

    img {
      border-radius: 12px;
    }
  }

  .text {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #fff;

    h2 {
      font-size: 36px;
      margin-bottom: 21px;
      @include breakpoint-up('sm') {
        font-size: 48px;
      }
    }

    .btn-wrap {
      width: 145px;
      margin: auto;

      a {
        min-width: 0;
      }
    }
  }
}
